import React from 'react';
import styled from 'styled-components';
import { BoldText, Title } from '../../../styles/TextStyles';

const ResponsiveImage = styled.img`
  max-height: 50vh; // Set maximum height to 50% of the viewport height
  width: 100%; // Make sure the image takes the full width of its container
  object-fit: contain; // Maintain the aspect ratio of the image
`;
const ImageOne = styled.div`
    padding-top: 30px;
    width: 100%;
    height: 100%;
    object-fit: cover;
    transition: transform 0.5s ease-in-out;
    transform: rotate(-5deg);
`;

const ImageTwo = styled.div`
    padding-top: 30px;
    width: 100%;
    height: 100%;
    object-fit: cover;
    transition: transform 0.5s ease-in-out;
    transform: rotate(5deg);    
`;

const LeftTextContainer = styled.div`
    text-align: left;
    display: inline-block; // Allows the text to shrink-wrap its content
`;
const RightTextContainer = styled.div`
    text-align: right;
    display: inline-block; // Allows the text to shrink-wrap its content
`;


function FirstUserInfromation() {
    const deptDetalImage = "assets/dept-detail.png";
    const pastTripsImage = "assets/past-trips.png";
    
    return (
        <div>
            <Title>Kostenlos für Erstnutzer!</Title>
            <BoldText>Registrieren, Übersicht haben & Geld sparen</BoldText>

            <table>
                <thead>
                    <tr>
                        <th className="image hidden">
                            <ImageOne><ResponsiveImage src={pastTripsImage} alt="Image of past trips" /></ImageOne>
                        </th>
                        <th style={{ padding: `0 5vw` }}></th>
                        <th className="image hidden">
                            <ImageTwo><ResponsiveImage src={deptDetalImage} alt="Image of dept detail overview of a fuel expense" /></ImageTwo>
                        </th>
                    </tr>
                </thead>
                <tbody>
                    <tr>
                        <td className="text-content hidden" style={{ width: "20vw"}}><LeftTextContainer><p>Fahrzeugnutzung im Überblick behalten.</p></LeftTextContainer></td>
                        <td></td>
                        <td className="text-content hidden" style={{ width: "20vw"}}><RightTextContainer><p>Kosten nur für die gefahrene Strecke tragen.</p></RightTextContainer></td>
                    </tr>
                </tbody>
            </table>
        </div>
    );
}

export default FirstUserInfromation;