import styled from 'styled-components';


const HeaderDiv = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px;
  background-color: #f5f5f5; /* Example background color */
`;

const NavLinks = styled.nav`
  display: flex;
  gap: 10px; // Adds spacing between the links
  padding-right: 30px;
`;

// Define a styled component for the individual navigation links
const NavLinkHover = styled.a`
  color: blue; // Example link color
  text-decoration: none;
  padding-left: 10px; // Add padding to the left to prevent text from touching the border
  border-left: 2px solid black;

  &:hover {
    text-decoration: underline;
  }
`;

function Header() {
    return (
        <HeaderDiv>
            <div></div>
            <NavLinks>
                <NavLinkHover href="/#">Startseite</NavLinkHover>
                <NavLinkHover href="/?to=supported-platforms" >Herunterladen</NavLinkHover>
                <NavLinkHover href="/about" >Über uns</NavLinkHover>
            </NavLinks>
        </HeaderDiv>
    );
}
export default Header;