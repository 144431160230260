import React from 'react';
import QRCode from 'react-qr-code';
import { BoldText } from '../../../styles/TextStyles';
import { AppStoreButton, GooglePlayButton } from 'react-mobile-app-button';

function SupportedPlatforms() {
    const androidAppLink = 'https://play.google.com/store/apps/details?id=tb.budget.drive';
    const iosAppLink = 'https://apps.apple.com/app/budget-drive/id6499519542';
   
    const platformSize = 157;
    const centerStyle = {width: `${platformSize+23}px`, marginLeft: "auto", marginRight: "auto",  display: "block", paddingTop: "30px"}

    // const qrSize = "8vw"
    const qrSize = window.innerWidth * 0.15;
    const centerQrStyle = { width: `${qrSize}px`, marginLeft: "auto", marginRight: "auto",  display: "block", paddingTop: "15px"}
    return (
        <div id='supported-platforms'>
            <BoldText>
                Laden Sie sich Budget Drive auf folgende Platformen herunter:
            </BoldText>
            <table>
                <thead>
                    <tr>
                        <th className="image hidden" style={centerStyle}>
                            <GooglePlayButton
                                width={platformSize}
                                title='Erhältlich bei'
                                url={androidAppLink}
                                theme={"light"}
                            />
                        </th>
                        <th style={{ padding: `0 5vw` }}></th>
                        <th className="image hidden" style={centerStyle}>
                            <AppStoreButton
                                width={platformSize}
                                title='Herunterladen von'
                                url={iosAppLink}
                                theme={"light"}
                            />
                        </th>
                    </tr>
                </thead>
                <tbody>
                    <tr>
                        <td className="text-content hidden" style={centerQrStyle}>
                            <QRCode
                            size={qrSize} 
                             value={androidAppLink}/>
                        </td>
                        <td style={{ padding: `0 5vw` }}></td>
                        <td className="text-content hidden" style={centerQrStyle}>
                            <QRCode 
                            size={qrSize} 
                            value={iosAppLink} />
                        </td>
                    </tr>
                </tbody>
            </table>
        </div>
    );
}

export default SupportedPlatforms;