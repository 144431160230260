import React from 'react';
import BodyBackground from '../BodyBackground';
import { Subtitle, Title } from '../../styles/TextStyles';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faInstagram, faYoutube } from '@fortawesome/free-brands-svg-icons';

class AboutPage extends React.Component {
    componentDidMount() {
        const observer = new IntersectionObserver((entries) => {
            entries.forEach((entry) => {
                if(entry.isIntersecting) {
                    entry.target.classList.add('show')
                } 
                // else {
                //     entry.target.classList.remove('show')
                // }
            })
        })
        const hiddenElements = document.querySelectorAll('.hidden')
        hiddenElements.forEach((e) => observer.observe(e))
    }

    render() { 
        const email = "service@budget-drive.com"

        const centerStyle = {marginLeft: "auto", marginRight: "auto",  display: "block", paddingTop: "30px"}
        return (
            <BodyBackground 
            DisableLogoAndTitle={true}
            Child={
                <div>
                    <section className={"hidden"}>
                        <Title>{"Über uns"}</Title>
                        <p>
                            Unabhängiger Entwickler: 
                        </p>
                        <p>
                            Balint Taschner-Berenguer Msc
                        </p>
                        <p>
                            von Österreich
                        </p>
                    </section>
                    <section className={"hidden"}>
                        <Subtitle>{"Kontaktieren Sie uns"}</Subtitle>
                        <p>
                            Für weitere Hilfe kontaktieren Sie bitte unser Service-E-Mail:
                        </p>
                        <a href={`mailto:${email}?subject=Liebe%20Budget-Drive`}>
                            { email }
                        </a>
                    </section>
                    <section className={"hidden"}>
                        <Subtitle>{"Soziale Medien"}</Subtitle>
                        <p>
                            Für Updates folgen Sie unseren Social Medias.
                        </p>
                        <table>
                            <tbody>
                                <tr>
                                    <th className="image hidden" style={centerStyle}>
                                        <a href="https://www.youtube.com/@budget-drive" target="_blank" rel="noopener noreferrer">
                                            <FontAwesomeIcon icon={faYoutube} style={{ fontSize: '50px' }} color="#ff0000"/>
                                        </a>
                                    </th>
                                    <th style={{ padding: `0 5vw` }}></th>
                                    <th className="image hidden" style={centerStyle}>
                                        <a href="https://www.instagram.com/budgetdrive/" target="_blank" rel="noopener noreferrer">
                                            <FontAwesomeIcon icon={faInstagram} style={{ fontSize: '50px' }} color="#000000"/>
                                        </a>
                                    </th>
                                </tr>
                            </tbody> 
                        </table>
                    </section>
                </div>
            }/>
        );
    }
}

export default AboutPage;