import React from 'react';
import BodyBackground from '../BodyBackground';
import FirstUserInfromation from './page-sections/FirstUserInfromation';
import SupportedPlatforms from './page-sections/SupportedPlatforms';
import { Title } from '../../styles/TextStyles';
import Logo from '../widgets/Logo';

class HomePage extends React.Component {
    componentDidMount() {
        const observer = new IntersectionObserver((entries) => {
            entries.forEach((entry) => {
                if(entry.isIntersecting) {
                    entry.target.classList.add('show')
                } 
                // else {
                //     entry.target.classList.remove('show')
                // }
            })
        })
        const hiddenElements = document.querySelectorAll('.hidden')
        hiddenElements.forEach((e) => observer.observe(e))
        
        const params = new URLSearchParams(window.location.search);
        const toParam = params.get('to');
        if (toParam) {
            const element = document.getElementById(toParam);
            if (element) {
                console.log(`scroll to  ${element}`)
                setTimeout(() => {
                  element.scrollIntoView({ behavior: 'instant', block: 'start', inline: "nearest" });
                }, 100);
            }
        }
    }

    render() { 
        return (
            <BodyBackground 
            DisableLogoAndTitle={true}
            Child={
                <div>
                    <section className={"hidden"}>
                        <Logo/>
                        <Title>{"Willkommen bei Budget Drive"}</Title>
                        <p>
                            Beherrschen Sie geteilte Autokosten mühelos mit Budget Drive für professionelle finanzielle Kontrolle.
                        </p>
                    </section>
                    <section className={"hidden"}>
                        <FirstUserInfromation/>
                    </section>
                    <section className={"hidden"}>
                        <SupportedPlatforms/>
                    </section>
                </div>
            }/>
        );
    }
}

export default HomePage;