import React from 'react';
import BodyBackground from '../BodyBackground';

function EmailConfirmationPage() {
    return (
        <BodyBackground
            Text={"E-Mail-Bestätigung für Budget Drive"} 
            Child={
            <div>
                <p>
                Ihre E-Mail wurde bestätigt und Sie können sich nun in der Budget Drive App anmelden.
                </p>
                <p>
                    Ihre Budget Drive Service.
                </p>
            </div>
        }/>
    );
}

export default EmailConfirmationPage;