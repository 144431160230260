import Footer from "./Footer";
import styled from 'styled-components';
import Logo from "./widgets/Logo";
import { Title } from "../styles/TextStyles";
import Header from "./Header";

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  min-height: 100vh;
`;

const BackgroundCard = styled.div`
  background-color: white; 
  padding-top: 30px;
  padding-bottom: 30px;
  width: 90%;
`;


function BodyBackground({Child, Text, DisableLogoAndTitle}) {
    return (
      <Wrapper>
        <Header/>
        <center>
          <BackgroundCard>
              {DisableLogoAndTitle 
              ? <div></div>
              : <div>
                <Logo />
                <Title>{Text ?? "Willkommen bei Budget Drive"}</Title>
              </div>}
            {Child ?? <div></div>}
          </BackgroundCard>
        </center>
        <Footer/>
      </Wrapper>
    );
}

export default BodyBackground;