import { Link } from 'react-router-dom';
import styled from 'styled-components';


const FooterPosition = styled.div`
  width: 100%;
  padding: 10px; /* Adjust padding as needed */
  background-color: #f0f0f0; /* Optional: Add a background color for visibility */
  position: relative; /* Change from absolute to relative */
  margin-top: auto;  
`;

const IntegrityLink = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`;

function Footer() {
    return (
      <FooterPosition>
        <IntegrityLink>
          <div>
            Von <Link to="/#">budget-drive.com</Link>
          </div>
        </IntegrityLink>
      </FooterPosition>
    );
}
export default Footer;