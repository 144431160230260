import styled from 'styled-components';
import { COLORS } from './colors.js';

const Title = styled.h1`
 font-size: 48px;
 font-weight: 700;
 letter-spacing: 0.48px;
 text-align: center;
 color: ${COLORS.purple};
`;

const Subtitle = styled.h1`
 font-size: 32px;
 font-weight: 700;
 letter-spacing: 0.48px;
 text-align: center;
 color: ${COLORS.purple};
`;

const BoldText = styled.p`
    font-weight: bold;
`;

export { Title, BoldText, Subtitle };