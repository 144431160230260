import React, { useEffect, useState } from 'react';
import BodyBackground from '../BodyBackground';
import MarkdownIt from 'markdown-it';
import { PRIVACY_POLICY_PATH } from '../../utils/EnvironmentVariables';
import styled from 'styled-components';

const md = new MarkdownIt();

async function PrivacyPolicy(setPolicy) {        
    try {
        console.log(PRIVACY_POLICY_PATH)
        const markdown = await (await fetch(PRIVACY_POLICY_PATH)).text()
        const html = md.render(markdown);
        setPolicy(html)
    } catch (err) {
        console.error(err);
        setPolicy(`Error while reading privacy policy: ${err}`);
    }
}

const LeftAlignedDiv = styled.div`
 text-align: left;
`;

function PrivacyPolicyPage() {
    
    const [policy, setPolicy] = useState();

    useEffect(() => {
        PrivacyPolicy(setPolicy)
    }, []);

    return (
        <BodyBackground  Text={"Datenschutzerklärung von Budget Drive"} Child={
            <LeftAlignedDiv dangerouslySetInnerHTML={{ __html: policy }} />
        }/>
    );
}

export default PrivacyPolicyPage;